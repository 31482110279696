import { watch } from 'vue';
<!--
 * @Author: goodLiang
 * @Date: 2023-07-08 15:59:10
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-14 17:51:07
 * @FilePath: /business-cms/src/views/shop/bank-list.vue
-->
<template>
  <div class="app-container">
    <CustomCard>
      <div class="i-m-b-20">
        <el-button type="primary" @click="addDialog = true">新增</el-button>
      </div>
      <el-table
        :header-cell-style="{
          'text-align': 'center',
          background: '#fafafa',
          color: '#444'
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
        :data="tableData"
      >
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="bank_account_no" label="银行卡号">
        </el-table-column>
        <el-table-column
          prop="bank_card_type_name"
          label="银行卡类型"
        ></el-table-column>
        <el-table-column label="操作" v-slot="scope">
          <el-button
            type="primary"
            @click="delBank(scope.row.bank_bind_id)"
            :disabled="tableData.length == 1"
            >删除</el-button
          >
        </el-table-column>
      </el-table>
    </CustomCard>
    <el-dialog
      title="新增银行卡"
      v-model="addDialog"
      width="500px"
      @close="handleCloseDialog"
    >
      <el-form
        ref="addFormRef"
        :model="addForm"
        label-position="left"
        label-width="110px"
        :rules="rules"
        ><el-form-item label="银行卡类型" prop="bank_card_type">
          <el-select
            v-model="addForm.bank_card_type"
            placeholder="请选择银行卡类型"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in bankOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="
                (shopType == 1 && index == 0) || (shopType == 3 && index == 1)
              "
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="银行名称" prop="bank_code">
          <el-select
            v-model="addForm.bank_code"
            placeholder="请选择银行"
            style="width: 100%"
            filterable
          >
            <el-option
              v-for="item in bankCodeList"
              :key="item.bank_code"
              :label="item.bank_name"
              :value="item.bank_code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="银行卡号" prop="bank_account_no">
          <el-input
            v-model="addForm.bank_account_no"
            placeholder="请输入银行卡号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="addDialog = false">取消</el-button>
          <el-button type="primary" @click="addBank">新增</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { to } from 'await-to-js'
import {
  getBankList,
  addBank,
  delBank,
  getShopDetail,
  getBankCode
} from '@/api/shop'
export default {
  data() {
    return {
      tableData: [],
      addDialog: false,
      bankOptions: [
        {
          label: '借记卡',
          value: 'DEBIT_CARD'
        },
        {
          label: '对公账号',
          value: 'ENTERPRISE_ACCOUNT'
        }
      ],
      addForm: {
        bank_card_type: null,
        bank_account_no: null,
        bank_name: null,
        bank_code: null
      },
      rules: {
        bank_card_type: [
          { required: true, message: '请选择银行卡类型', trigger: 'change' }
        ],
        bank_code: [
          { required: true, message: '请选择银行', trigger: 'change' }
        ],
        bank_account_no: [
          { required: true, message: '请输入银行卡号', trigger: 'blur' }
        ]
      },
      shopType: '',
      bankCodeList: []
    }
  },
  watch: {
    'addForm.bank_code'(newVal) {
      this.addForm.bank_name = this.bankCodeList.find(
        (bank) => bank.bank_code === newVal
      ).bank_name
    }
  },
  mounted() {
    this._initData()
    this._getShopDetail()
    this._getBankCode()
  },
  methods: {
    async _initData() {
      const [, res] = await to(
        getBankList({ seller_id: this.$route.params.id })
      )
      if (res) {
        this.tableData = res.bank_list_vo
      }
    },
    async _getShopDetail(id) {
      const [, res] = await to(
        getShopDetail({ seller_id: this.$route.params.id })
      )
      if (res) {
        this.shopType = res.account_type
      }
    },
    async _getBankCode(id) {
      const params = {
        page_index: 1,
        page_size: 5000
      }
      const [, res] = await to(getBankCode(params))
      if (res) {
        this.bankCodeList = res.data
      }
    },
    async delBank(id) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const [, res] = await to(delBank({ bank_bind_id: id }))
          if (res) {
            this.$message.success('删除成功')
            this._initData()
          }
        })
        .catch(() => {})
    },
    addBank() {
      this.$refs.addFormRef.validate(async (valid) => {
        const params = {
          seller_id: this.$route.params.id,
          ...this.addForm
        }
        if (valid) {
          const [, res] = await to(addBank(params))
          if (res) {
            this.addDialog = false
            this.$message.success('添加成功')
            this._initData()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleCloseDialog() {
      this.$refs.addFormRef.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped></style>
